import React from 'react';
import './Navbar.scss';
import arrow from '../../../../assets/backArr.png';
import homeicon from '../../../../assets/home.svg';
import newsicon from '../../../../assets/loudspeaker.svg';
const Navbar = () => {
  return (
    <div className='navbar_cont'>

            <div>
                <img src={arrow} alt="" />
            </div>

            <div>

            </div>

            <div className='nav_links'>
                <div>
                    <img src={homeicon} alt="" />
                    <h4>Home</h4>
                </div>
                
                <h4 style={{color:'#9CA0A5'}}>/</h4>

                <div>
                    <img src={newsicon} alt="" />
                    <h4>News</h4>
                </div>
            
            </div>
    </div>
  )
}

export default Navbar
